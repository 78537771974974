@import "_generated-font-mixins.scss";
.sidebar {
  overflow: hidden;
  width: 100%;
  max-width: 240px;

  &__b2b {
    width: 240px;

    & img {
      width: inherit;
      height: 640px;
      border-radius: 4px;
    }
  }

  &__b2c {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 240px;
    height: 640px;

    & img {
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}

.admixer * {
  border-radius: 4px;
}
